<template>
  <div class="products">
    <div class="page-header">
      <h1>{{ $t('Inventory Management') }} > {{ $t('Inventory') }}</h1>
    </div>
    <div class="page-content">
      <div class="content-header">
        <h2>{{ $t('Inventory') }}</h2>
      </div>
      <ItemFilter :placeholder="$t('Search by product name')" default="productName" :selection="false" @search="searchItems">
        <el-option label="Product Name Search" value="productName"></el-option>
      </ItemFilter>

      <div class="inventory-list">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">{{ $t('Photo') }}</th>
              <th scope="col">{{ $t('Product Name')}}</th>
              <th scope="col">{{ $t('Status') }}</th>
              <th scope="col">{{ $t('Quantity') }}</th>
              <th scope="col">{{ $t('Update Quantity') }}</th>
            </tr>
          </thead>
          <tbody v-for="(product, productIndex) in products">
            <tr>
              <td class="product-image-td">
                <div class="product-image-wrapper">
                  <img class="product-image img-fluid" v-if="getProductImage(product.product_images)" :src="getProductImage(product.product_images)" />
                  <img class="product-image img-fluid" v-else src="@/assets/no-image.jpg" />
                </div>
              </td>
              <td v-if="langcode === 'zh'">{{ product.product_name_zh }}</td>
              <td v-else>{{ product.product_name_en }}</td>
              <td v-if="product.product_published == true">
                {{ $t('Published') }}
              </td>
              <td v-else>
                {{ $t('Unpublished') }}
              </td>
              <td v-if="product.product_quantity">
                {{ product.product_quantity }}
                <div class="product_not_enough" v-if="product.product_quantity < 10">
                  {{ $t('Understocking')}}
                </div>
              </td>
              <td v-else> {{ $t('Unlimited') }}</td>
              <td>
                <div class="product-quantity-editor" v-if="product.product_quantity || product.product_quantity === 0 ">
                  <input type="number" class="form-control" :value="product.product_quantity" :placeholder="$t('Product Quantity')" :ref="`${product.id}`">
                  <el-button @click="updateProductQuantity(product)">{{ $t('Save') }}</el-button>
                </div>
              </td>
            </tr>

            <tr class="options-list" v-for="(option, optionIndex) in getVariationOptions(product.product_variations)">
              <td></td>
              <td v-if="langcode === 'zh'">{{ option.option_name_zh }}</td>
              <td v-else>{{ option.option_name_en }}</td>
              <td></td>
              <td>{{ option.option_quantity }}</td>
              <td>
                <div class="product-quantity-editor" v-if="option.option_quantity">
                  <input type="number" class="form-control" :value="option.option_quantity" :placeholder="$t('Product Quantity')" :ref="`${option.option_name_en}-${product.id}`">
                  <el-button @click="updateOption(product, option)">{{ $t('Save') }}</el-button>
                </div>
              </td>
            </tr>

          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ItemFilter from '@/components/Filter.vue';
import Common from '@/lib/common';
import Product from '@/lib/product';

export default {
  name: 'Inventory',
  components: {
    ItemFilter,
  },
  mounted(){
    this.loadProducts();
  },
  data(){
    return {
      orignalProducts: [],
      products: [],
    }
  },
  methods:{
    searchItems(form){
      const searchField = {};
      let newItems = [];
      switch(form.filterTarget){
        case 'productName':
        if(this.langcode === 'zh'){
          searchField['product_name_zh'] = form.filterValue;
        }else{
          searchField['product_name_en'] = form.filterValue;
        }
        newItems = Common.filterItems(searchField, this.orignalProducts);
        this.products = newItems;
        break;
      }
    },
    async updateProductQuantity(product){
      let newQuantity = this.$refs[`${product.id}`][0];
      newQuantity = newQuantity.value;
      const productInfo = this.loadProductToForm(product);
      productInfo.quantity = newQuantity;
      await this.updateProduct(product.id, productInfo);
      //location.reload();
    },
    async updateOption(product, option){
      let newOptionQuantity = this.$refs[`${option.option_name_en}-${product.id}`][0];
      newOptionQuantity = newOptionQuantity.value;
      const productInfo = this.loadProductToForm(product);
      for(let i = 0; i < productInfo.variations.length; i++){
        const variation = productInfo.variations[i];
        const targetOptionIndex = variation.options.findIndex(function(item){
          if(option.option_name_en === item.optionNameEn && option.option_name_zh === item.optionNameZh){
            return true;
          }
        });
        if(targetOptionIndex !==  -1){
          //Update value
          productInfo.variations[i]['options'][targetOptionIndex]['optionQuantity'] = newOptionQuantity;
        }
      }
      await this.updateProduct(product.id, productInfo);
      location.reload();
    },
    async updateProduct(productId, productInfo){
      try{
        const loginInfo = Common.getLoginInfo();
        const products = await Product.updateProduct(this.apiUrl, productId, productInfo, loginInfo);
      }catch(err){
        console.log(err);
      }
    },
    loadProductToForm(product){
      const productInfo = {
        categories: [],
        productNameZh: product.product_name_zh,
        productNameEn: product.product_name_en,
        productDescriptionZh: product.product_description_zh,
        productDescriptionEn: product.product_description_en,
        productImages: [],
        unlimitedQuantity: false,
        quantity: product.product_quantity,
        regularPrice: product.product_regular_price,
        variations: [],
      };

      for(let i = 0 ; i < product.product_categories.length; i++){
        productInfo.categories.push(product.product_categories[i].id);
      }

      if(product.product_published == 1){
        productInfo.published = true;
      }else{
        productInfo.published = false;
      }
      if(!product.product_quantity){
        productInfo.unlimitedQuantity = true;
      }
      if(product.product_published == 1){
        productInfo.published = true;
      }else{
        productInfo.published = false;
      }

      if(!product.product_quantity){
        productInfo.unlimitedQuantity = true;
      }

      if(product.had_variation == 1 && product.product_variations){
        productInfo.hadVariations = true;
        const variations = JSON.parse(product.product_variations);
        for(let i = 0; i < variations.length; i++){
          const variation = variations[i];
          const variationInfo = {
            'nameEn': variation.variation_name_en,
            'nameZh': variation.variation_name_zh,
            'options': [],
          };
          for(let a = 0; a < variation.options.length; a++){
            const option =  variation.options[a];
            const optionInfo = {
              'optionNameEn': option.option_name_en,
              'optionNameZh': option.option_name_zh,
              'optionPrice': option.option_price,
              'optionQuantity': option.option_quantity,
            }
            if(option.option_quantity){
              optionInfo.unlimitedQuantity = false;
            }else{
              optionInfo.unlimitedQuantity = true;
            }
            variationInfo.options.push(optionInfo);
          }
          productInfo.variations.push(variationInfo);
        }
      }else{
        productInfo.hadVariations = false;
      }

      //Load proudct image
      const productImages = JSON.parse(product.product_images);
      for(let i = 0; i < productImages.length; i++){
        const imageUrl = productImages[i];
        if(imageUrl){
          const imageFileName = imageUrl.substring(imageUrl.lastIndexOf('/')+1);
          const imageFile = {
            name: imageFileName,
            url: imageUrl,
          };
          productInfo.productImages.push(imageUrl);
        }
      }
      return productInfo;
    },
    getVariationOptions(variationsString){
      const variations = JSON.parse(variationsString);
      let options = [];
      for(let i = 0; i < variations.length; i++){
        const variation = variations[i];
        const variationOptions = variation.options;
        options = options.concat(variationOptions);
      }
      return options;
    },
    getProductImage(imageField){
      if(imageField){
        const images = JSON.parse(imageField);
        const image = images[0];
        return image;
      }
    },
    getProductCategoires(product){
      const productCategories = [];
      for(let i = 0; i < product.product_categories.length; i++){
        const productCategorie = product.product_categories[i];
        if(this.langcode === 'zh'){
          productCategories.push(productCategorie.categorie_name_zh);
        }else{
          productCategories.push(productCategorie.categorie_name_en);
        }
      }
      const productCategoriesString = productCategories.join(', ');
      return productCategoriesString;
    },
    async loadProducts(){
      try{
        const loginInfo = Common.getLoginInfo();
        const products = await Product.loadAllProducts(this.apiUrl, loginInfo);
        this.orignalProducts = products;
        this.products = products;
      }catch(err){
        console.log(err);
      }
    },
  },
  computed: mapState({
   langcode: state => state.langcode,
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style scoped lang="scss">
.content-header{
  display: flex;
  h2{
    flex: 1;
  }
  .action-button{
    margin-right: 20px;
  }
}
.product-image-wrapper{
  width: 75px;
  height: 75px;
  position: relative;
  overflow: hidden;
  .product-image{
    overflow: hidden;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.product-image-td{
  width: 20%;
}

.product-quantity-editor{
  display: flex;
  .el-input, .form-control{
    max-width: 20%;
    margin-right: 50px;
  }
}
.product_not_enough{
  color: red;
}
</style>
